import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";

const CURRENT_USER_QUERY = gql`
  query CURRENT_USER {
    me {
      id
      icon
      iconType
      email
      firstname
      lastname
      teacher {
        id
        trained
        plModules
        school {
          id
          name
          access
        }
        selectedClass {
          id
          classname
          grade
          phase
          term
          levelsOpen
          rollout
        }
      }
      student {
        id
        school {
          id
          name
        }
        class {
          id
          grade
          term
          phase
          classname
          levelsOpen
          rollout
        }
      }
    }
  }
`;

const User = (props) => (
  <Query {...props} query={CURRENT_USER_QUERY}>
    {(payload) => props.children(payload)}
  </Query>
);

User.propTypes = {
  children: PropTypes.func.isRequired,
};

export default User;
export { CURRENT_USER_QUERY };
