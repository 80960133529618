import React, { useState, useEffect } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import Form from "./styles/Form";
import Error from "./ErrorMessage";
import RequestReset from "./RequestReset";
import { CURRENT_USER_QUERY } from "./User";
import styled from "styled-components";
import redirect from "../lib/redirect";

const SIGNIN_TEACHER_MUTATION = gql`
  mutation SIGNIN_TEACHER($email: String!, $password: String!) {
    signInTeacher(email: $email, password: $password) {
      id
      firstname
      lastname
      student {
        id
      }
      teacher {
        id
        school {
          id
          name
        }
        selectedClass {
          id
          classname
        }
      }
    }
  }
`;

const Signin = (props) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordReset, setPasswordReset] = useState(
    props.passwordReset || false
  );

  useEffect(() => {
    window.Beacon("init", "ee28b5bb-bd1f-446e-863b-8a2ae43a6224");
  });

  return (
    <Column>
      {!passwordReset ? (
        <Mutation
          mutation={SIGNIN_TEACHER_MUTATION}
          variables={{ email, password }}
          refetchQueries={[{ query: CURRENT_USER_QUERY }]}
          onCompleted={(data) => {
            redirect({}, `/challenges/progress`);
          }}
        >
          {(signup, { data, error, loading, called }) => (
            <div>
              <Form
                id="signin"
                method="post"
                onSubmit={async (e) => {
                  e.preventDefault();
                  await signup();
                  setPassword("");
                  setEmail("");
                }}
              >
                <fieldset disabled={loading} aria-busy={loading}>
                  <h2>Sign into your teacher account</h2>
                  <Error error={error} />
                  <label htmlFor="email">
                    Email
                    <input
                      type="email"
                      name="email"
                      list="data"
                      placeholder="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <datalist id="data">
                      {["@det.nsw.edu.au", "@cg.catholic.edu.au"].map(
                        (item, key) => (
                          <option key={key} value={email + item} />
                        )
                      )}
                    </datalist>
                  </label>
                  <label htmlFor="password">
                    Password
                    <input
                      type="password"
                      name="password"
                      placeholder="password"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </label>
                  <Row>
                    <button type="submit">Sign In</button>

                    <span onClick={() => setPasswordReset(true)}>
                      Forgot your Password?
                    </span>
                  </Row>
                </fieldset>
              </Form>
            </div>
          )}
        </Mutation>
      ) : (
        <div>
          <RequestReset email={email}>
            <span onClick={() => setPasswordReset(false)}>Sign In</span>
          </RequestReset>
        </div>
      )}
    </Column>
  );
};

export default Signin;

const Column = styled.div`
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 50vw;
  background-color: white;
  border-radius: 20px;
`;

const Row = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;
